// Routes

"use strict";

import { createRouter, createWebHashHistory, Router } from "vue-router";

// Router
// https://router.vuejs.org/guide/#javascript

const routes = [
    {
        name: "home",
        path: '/',
        component: () => import("@/components/routes/HomePage.vue"),
    },

    {
        name: "about",
        path: '/about',
        component: () => import("@/components/routes/info/AboutPage.vue"),
    },

    {
        name: "help",
        path: '/help',
        component: () => import("@/components/routes/info/HelpPage.vue"),
    },

    {
        name: "terms",
        path: '/terms',
        component: () => import("@/components/routes/info/TermsPage.vue"),
    },

    {
        name: "privacy",
        path: '/privacy',
        component: () => import("@/components/routes/info/PrivacyPolicyPage.vue"),
    },

    {
        name: "cookies",
        path: '/cookies',
        component: () => import("@/components/routes/info/CookiesPolicyPage.vue"),
    },

    {
        name: "wallet-verification",
        path: '/wallet/verification',
        component: () => import("@/components/routes/kyc/WalletVerification.vue")
    },

    {
        name: "login",
        path: '/login',
        component: () => import("@/components/routes/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: '/login/tfa',
        component: () => import("@/components/routes/TwoFactorLoginPage.vue"),
    },

    {
        name: "wallet-create",
        path: '/wallet/create',
        component: () => import("@/components/routes/WalletCreationPage.vue"),
    },

    {
        name: "signup",
        path: '/signup',
        component: () => import("@/components/routes/SignupPage.vue"),
    },

    {
        name: "tp-login",
        path: "/login/tp/:service",
        component: () => import("@/components/routes/ThirdPartyLogin.vue"),
    },

    {
        name: "tp-signup",
        path: "/signup/tp",
        component: () => import("@/components/routes/ThirdPartySignupPage.vue"),
    },

    {
        name: "signup-success",
        path: '/signup/success',
        component: () => import("@/components/routes/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: '/password/forgot',
        component: () => import("@/components/routes/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: '/password/reset/:uid/:token',
        component: () => import("@/components/routes/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: '/email/verify/:uid/:token',
        component: () => import("@/components/routes/EmailVerifyPage.vue"),
    },

    {
        name: "invalid-certificate",
        path: '/error/invalid_cert',
        component: () => import("@/components/routes/InvalidCertificatePage.vue"),
    },

    {
        name: "valid-certificate-callback",
        path: '/cert_callback',
        component: () => import("@/components/routes/ValidCertificateCallback.vue"),
    },
    
    {
        name: "admin-users",
        path: '/admin/users',
        component: () => import("@/components/routes/admin/UsersAdministration.vue"),
    },

    {
        name: "admin-users-single",
        path: '/admin/users/:id',
        component: () => import("@/components/routes/admin/UsersAdministrationSingle.vue"),
    },

    {
        name: "admin-wallet-verification-requests",
        path: '/admin/verification/requests',
        component: () => import("@/components/routes/kyc/VerificationRequestList.vue"),
    },

    {
        name: "admin-wallet-verification-request",
        path: '/admin/verification/request/:id',
        component: () => import("@/components/routes/kyc/VerificationRequestManage.vue"),
    },

    {
        name: "admin-verified-wallets",
        path: '/admin/wallets/verified',
        component: () => import("@/components/routes/kyc/VerifiedWalletsList.vue"),
    },

    {
        name: "admin-verified-wallet",
        path: '/admin/wallets/verified/:id',
        component: () => import("@/components/routes/kyc/VerifiedWalletManage.vue"),
    },

    {
        name: "admin-resolve-disputes",
        path: '/resolve-disputes',
        component: () => import("@/components/routes/DisputesPage.vue"),
    },

    {
        name: "admin-payment-methods",
        path: '/admin/payment.methods',
        component: () => import("@/components/routes/admin/PaymentMethodsAdministration.vue"),
    },

    {
        name: "activity",
        path: '/activity',
        component: () => import("@/components/routes/ActivityPage.vue"),
    },

    {
        name: "contracts",
        path: '/contracts',
        component: () => import("@/components/routes/ContractsPage.vue"),
    },

    {
        name: "contract",
        path: '/contract/:id',
        component: () => import("@/components/routes/ContractPage.vue"),
    },

    {
        name: "notifications",
        path: '/notifications',
        component: () => import("@/components/routes/NotificationsPage.vue"),
    },

    {
        name: "payment-methods",
        path: '/payment-methods',
        component: () => import("@/components/routes/PaymentMethodsPage.vue"),
    },

    {
        name: "profile",
        path: '/profile',
        component: () => import("@/components/routes/ProfilePage.vue"),
    },

    {
        name: "loan-requests",
        path: '/loan/requests',
        component: () => import("@/components/routes/ExploreLoanRequestsPage.vue"),
    },

    {
        name: "my-loan-requests",
        path: '/loan/my-requests',
        component: () => import("@/components/routes/LoanRequestsPage.vue"),
    },

    {
        name: "loan-request",
        path: '/loan/requests/:id',
        component: () => import("@/components/routes/LoanRequestPage.vue"),
    },

    {
        name: "loan-request-offers",
        path: '/loan/requests/:id/offers',
        component: () => import("@/components/routes/RequestOffersPage.vue"),
    },
    {
        name: "personal-form",
        path: '/personal-form',
        component: () => import("@/components/routes/PersonalForm.vue"),
    },

    {
        name: "loan-new-request",
        path: '/loan/new-request',
        component: () => import("@/components/routes/SubmitLoanRequestPage.vue"),
    },

    {
        name: "loan-offers",
        path: '/loan/offers',
        component: () => import("@/components/routes/LoanOffersPage.vue"),
    },

    {
        name: "loan-offer",
        path: '/loan/offers/:rid/:id',
        component: () => import("@/components/routes/LoanOfferPage.vue"),
    },

    {
        name: "disputes",
        path: '/disputes',
        component: () => import("@/components/routes/DisputesPage.vue"),
    },

    {
        name: "dispute",
        path: '/dispute/:id',
        component: () => import("@/components/routes/DisputePage.vue"),
    },

    {
        name: "admin-dispute",
        path: '/admin-dispute/:id',
        component: () => import("@/components/routes/DisputePage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHashHistory(),
        routes, // short for `routes: routes`
    })

    return router;
}
